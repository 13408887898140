<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
  >
    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-content>
          <img
            src="@/assets/imparfaite-white-logo.svg"
            alt="Imparfaite."
            height="30"
          >
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            v-if="drawer"
            elevation="1"
            class="mr-2"
            fab
            small
            @click="setDrawer(!drawer)"
          >
            <v-icon>
              mdi-menu-open
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <template v-for="(item, i) in items">
        <v-list-item
          v-if="!item.items"
          :key="`item-${i}`"
          :to="item.to"
          link
          class="mb-0"
        >
          <v-list-item-icon class="mr-2">
            <v-icon
              v-if="item.icon"
              right
            >
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-else
          :key="item.title"
          no-action
          :append-icon="null"
        >
          <template v-slot:activator>
            <v-list-item
              :key="`item-${i}`"
              link
              class="mb-0 pl-0"
            >
              <v-list-item-icon class="mr-2">
                <v-icon
                  v-if="item.icon"
                  right
                >
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-for="(child, j) in item.items">
            <v-list-item
              :key="`child-item-${i}-${j}`"
              :to="child.to"
              link
              class="py-0 pl-0"
              style="max-height: 32px;height: 32px;min-height: 32px"
            >
              <v-list-item-icon class="mr-2 mt-2">
                <v-icon
                  v-if="child.icon"
                  small
                >
                  {{ child.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-subtitle
                class="white--text"
              >
                {{ child.title }}
              </v-list-item-subtitle>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>

    <template v-slot:append>
      <Item
        :item="{
          title: $t('menu.logout'),
          icon: 'mdi-logout',
          onClick:logout
        }"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Item from '@/components/base/Item.vue'

export default {
  name: 'CoreDrawer',
  components: { Item },

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      items: [],
    }
  },
  computed: {
    ...mapState(['barColor', 'drawer', 'roles']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('drawerDisplay', val)
      },
    },
  },
  mounted: function () {
    this.items = [
      {
        icon: 'mdi-chart-box-outline',
        title: this.$i18n.t('menu.dashboard'),
        to: '/',
      },
      {
        icon: 'mdi-store-outline',
        title: this.$i18n.t('menu.stores.list'),
        items: [
          {
            title: this.$i18n.t('menu.stores.list'),
            to: '/stores/list',
          },
          {
            title: this.$i18n.t('menu.sellers.list'),
            to: '/sellers/list',
          },
        ],
      },
      {
        icon: 'mdi-hanger',
        title: this.$i18n.t('menu.products.main'),
        items: [
          {
            title: this.$i18n.t('menu.products.list'),
            to: '/products/list',
          },
          {
            title: this.$i18n.t('menu.curation.list'),
            to: '/curation/list?state_and_reason[]=pending_internal_review',
          },
        ],
      },
      {
        icon: 'mdi-database-eye-outline',
        title: this.$i18n.t('menu.external_catalog.main'),
        items: [
          {
            title: this.$i18n.t('menu.external_catalog.providers'),
            to: '/external-catalog/providers/list',
          },
          {
            title: this.$i18n.t('menu.external_catalog.list'),
            to: '/external-catalog/synchro-lines/list',
          },
          {
            title: this.$i18n.t('menu.external_catalog.classification'),
            to: '/external-catalog/classifications/list',
          },
        ],
      },
      {
        icon: 'mdi-water-opacity',
        title: this.$i18n.t('menu.drops.title'),
        items: [
          {
            title: this.$i18n.t('menu.drops.calendar'),
            to: '/drops/calendar',
          },
          {
            title: this.$i18n.t('menu.drops.list'),
            to: '/drops/list',
          },
        ],
      },
      {
        icon: 'mdi-wallet',
        title: this.$i18n.t('menu.price_negotiations.list'),
        to: '/price_negotiations/list',
      },
      {
        icon: 'mdi-image-outline',
        title: this.$i18n.t('menu.photos.main'),
        to: '/photos/list',
      },
      {
        icon: 'mdi-package-variant',
        title: this.$i18n.t('menu.logistic.list'),
        items: [
          {
            title: this.$i18n.t('menu.tracking.list'),
            to: '/tracking/list',
          },
          {
            title: this.$i18n.t('menu.shipments.list'),
            to: '/shipments/list',
          },
          {
            title: this.$i18n.t('menu.returns.list'),
            to: '/returns/list',
          },
        ],
      },
      {
        icon: 'mdi-cog-outline',
        title: this.$i18n.t('menu.admin.list'),
        items: [
          {
            title: this.$i18n.t('menu.admin.tasks.list'),
            to: '/admin/tasks/list',
          },
          {
            title: this.$i18n.t('menu.admin.categories.list'),
            to: '/admin/categories/list',
          },
          {
            title: this.$i18n.t('menu.admin.taxonomies.list'),
            to: '/admin/taxonomies/list',
          },
          {
            title: this.$i18n.t('menu.admin.translations.list'),
            to: '/admin/translations/list',
          },
        ],
      },
    ]
  },
  methods: {
    ...mapMutations({
      setDrawer: 'drawerDisplay',
    }),
    logout() {
      this.$store.commit('logout')
      this.$router.push({ name: 'Login' })
    },
  },
}
</script>

<style lang="sass">

</style>
